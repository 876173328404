import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  // UI
  faEllipsisH, faUpload, faDownload, faEye,
  faPlus, faTrash, faTimes, faTimesCircle, faEdit,
  faCheck, faCheckCircle,
  faExclamation, faExclamationCircle, faExclamationTriangle,
  faCaretDown, faGripVertical,
  faExternalLinkAlt, faShare,
  faBell, faBellSlash,
  faArrowDownAZ,
  faUserPlus, faUserEdit, faUserMinus,
  faChevronLeft, faChevronRight,
  // Objects
  faLink, faInbox, faLock, faGear, faFloppyDisk, faCalendar,
  // Files
  faFile, faFileAlt, faFilePdf,
  faFileWord, faFileExcel, faFilePowerpoint,
  faFileImage, faFileAudio, faFileVideo,
  faFileArchive
} from '@fortawesome/free-solid-svg-icons'

library.add(
  // UI
  faEllipsisH, faUpload, faDownload, faEye,
  faPlus, faTrash, faTimes, faTimesCircle, faEdit,
  faCheck, faCheckCircle,
  faExclamation, faExclamationCircle, faExclamationTriangle,
  faCaretDown, faGripVertical,
  faExternalLinkAlt, faShare,
  faBell, faBellSlash,
  faArrowDownAZ,
  faUserPlus, faUserEdit, faUserMinus,
  faChevronLeft, faChevronRight,
  // Objects
  faLink, faInbox, faLock, faGear, faFloppyDisk, faCalendar,
  // Files
  faFile, faFileAlt, faFilePdf,
  faFileWord, faFileExcel, faFilePowerpoint,
  faFileImage, faFileAudio, faFileVideo,
  faFileArchive
)

config.mutateApproach = 'sync'
dom.watch()
