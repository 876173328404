// other_field_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "otherField"]
  static values = { otherFieldId: String }

  connect() {
    this.updateOtherField()

    // Check if the form was invalid and rerendered
    if (this.selectTarget.value === "") {
      const selectedOption = this.selectTarget.querySelector('option[selected]')
      if (selectedOption && selectedOption.dataset.otherProfessionTargetId) {
        this.showOtherField()
      }
    }
  }

  updateOtherField() {
    const selectedOption = this.selectTarget.selectedOptions[0]
    if (selectedOption && selectedOption.dataset.otherProfessionTargetId === this.otherFieldIdValue) {
      this.showOtherField()
    } else {
      this.hideOtherField()
    }
  }

  showOtherField() {
    this.otherFieldTarget.classList.remove('hidden')
    this.enableInputs()
  }

  hideOtherField() {
    this.otherFieldTarget.classList.add('hidden')
    this.disableInputs()
  }

  enableInputs() {
    this.otherFieldTarget.querySelectorAll('input, select, textarea').forEach(input => {
      input.disabled = false
      input.required = true
    })
  }

  disableInputs() {
    this.otherFieldTarget.querySelectorAll('input, select, textarea').forEach(input => {
      input.disabled = true
      input.required = false
    })
  }
}
